<template>
  <div id="">
    <div class="search-container " style="margin: 0 auto; width: 80%; margin-top: -4px">
      <div style="position: relative"
        class="
          row
          justify-content-between
          pt-2
          pb-2
          ml-3
          mr-3
          position-relative">
        <div>
          <img src="../../assets/img/search/3.0sxtk_search_icon_search.png" alt=""/>
        </div>
        <div  class="text-center mt-3"  style="position: absolute; right: 10px; top: -60px">
          <router-link to="/database/data-manager">
            <a  href="/database/data-manager"  class="d-inline-block btn-addCompany br_4 fz-14 cursor_pionter"  style="cursor: pointer">
              <i class="fz-14 mr-1">+</i> 新增我的企业
            </a>
          </router-link>
        </div>
        <div class="col">
          <input
            type="text"
            class="col"
            placeholder="请输入公司名称关键词"
            id="inputText"
            @click.stop
            @keydown.down="keyDownChoose"
            @keydown.up="keyUpChoose"
            v-model="searchText"
            @keyup="queryCompany"
            @focus="inputFous"
          />
        </div>
        <div
          class="cursor_pionter color-4ad"
          style="line-height: 30px"
          @click="searchCompany"
        >
          搜索
        </div>

        <div
          class="position-absolute search-list"
          v-show=" searchText != '' && searchLists.errMessage != '没有查询到结果！' && searchGood">
          <a  href="javascript:void(0)"  v-for="(item, index) in searchLists" :key="index"  class="d-block mt-2 search-items"  @mouseover="mouseClearStyle"  @click="stepToCompanyInfo(item)">
            <span class="color-4d search-hot-title d-inline-block fz-14 text-center">
              公司
            </span>
            <span class="color-4d ml-4 fz-14 text-center">{{ item.name }}</span>
            <span class="color-4d ml-10 fz-14 text-center"
              >({{ item.create_time }})</span
            >
          </a>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="w-100 mt-5">
        <div class="fz-14 color-4d">
          关于<span class="color-le fz-16">{{ keyword }}</span
          >的搜索共<span>{{ total }}</span
          >条
        </div>
        <div class="bgc_w mt-2 pt-3 pb-5 pl-4 pr-4">
          <div class="row pb-4 border-bottom pt-3" v-for="(company, key) in storeAll" :key="key">
            <div class="col-md-10 col-sm-12">
              <div class="row align-items-center">
                <span
                  class="fz-20 color-0 cursor_pionter"
                  @click="toCompanyDetail(company)"
                  >{{ company.name }}</span
                >
                <span
                  class="
                    fz-14
                    color-4ad
                    pt-1
                    pb-1
                    pl-2
                    pr-2
                    bgc-df
                    br_25
                    ml-2
                    mr-5
                  "
                  >我的企业</span
                >
              </div>
              <div class="row align-items-center mt-2">
                <div class="color-4d">
                  <span class="">统计局行业:</span>
                  <span>{{
                    company.tj_category && company.tj_category.industry_name
                  }}</span>
                </div>

                <span class="color-4d ml-3 fz-14">{{
                  company.create_time
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
            <v-pagination
              :total="total"
              @page-change="pageChange"
              @page-size-change="pageSizeChange"
              size="middle"
            ></v-pagination>
          </div>
    </div>
  </div>
</template>

<script>
import * as sesstion from "../../store/localStorage";
import { mapGetters, mapState, mapActions } from "vuex";
import page from "../page/page";
export default {
  name: "CauseResult",
  dev: "事业搜索",
  components: {
    pagination:page
  },
  data() {
    return {
      searchLists: [],
      searchText: "",
      searchGood: true,
      hotStand: 50,
      num: 0,
      array: [],
      lessArrays: [],
      isAdd: false,
      storeAll: [],
      total: 150, // 记录数据的总条数
      display: 10, // 每页显示数据的条数
      current: 1, // 当前的页数
      keyword: "",
      pageSize:'',
    };
  },
  created() {
    $("body").click(function () {
      $(".search-list").css("display", "none");
    });
    this.$store.state.headLogo = true;
    this.keyword = sesstion.getLocalData("searchText");
    this.queryCompanyList(1);
  },
  mounted() {
    // console.log($(`span:contains(上市公司)`))
  },
  methods: {
    ...mapActions([
      "queryPutData",
      "keyDownChoose",
      "keyUpChoose",
      "saveClickOrHoverIndex",
      "mouseClearStyle",
    ]),
    queryCompanyList(page) {
      var value = this.keyword;
      this.$http
        .get(
          window.BASE_URL +
            "/company/my/search-paging/" +
            value +
            "/" +
            3 +
            "/?page=" +
            page+"&page_size="+this.pageSize,
          {
            headers: {
              Authorization:
                "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
            },
          }
        )
        .then(
          (res) => {
            this.storeAll = res.data.companyList;
            //console.log( this.storeAll)
            this.total = res.data.total;
          },
          (res) => {
            // console.log("请求失败！")
          }
        );
    },
    queryCompany() {
      var value = this.searchText;
      if (value != "") {
        this.$http
          .get(
            window.BASE_URL + "/company/my/search/" + value + "/" + 3 + "/",
            {
              headers: {
                Authorization:
                  "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
              },
            }
          )
          .then(
            (res) => {
              this.searchLists = res.data;
            },
            (res) => {
              // console.log("请求失败！")
            }
          );
      }
    },
      pageChange(pageIndex) {
      // console.log(pageIndex);
      this.queryCompanyList(pageIndex);
    },
    pageSizeChange(pageSize) {
      
      this.pageSize=pageSize
      // console.log(this.pageSize);
      this.queryCompanyList(this.current);

    },
    stepToCompanyInfo(item) {
      sesstion.setLocalData("company", item);
      this.$router.push({ name: "causeCompany" });
    },
    searchCompany() {
      var value = this.searchText;
      sesstion.setLocalData("searchText", value);
      this.keyword = value;
      this.searchGood = false;
      this.$http
        .get(
          window.BASE_URL +
            "/company/my/search-paging/" +
            value +
            "/" +
            3 +
            "/?page=1",
          {
            headers: {
              Authorization:
                "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
            },
          }
        )
        .then(
          (res) => {
            this.storeAll = res.data.companyList;
            //console.log( this.storeAll)
            this.total = res.data.total;
          },
          (res) => {
            // console.log("请求失败！")
          }
        );
    },
    inputFous() {
      var value = this.searchText;
      if (value != "") {
        $(".search-list").css("display", "block");
      }
    },
    add(id) {
      this.isAdd = !this.isAdd;
    },
    toCompanyDetail(company) {
      sesstion.setLocalData("company", company);
      this.$router.push({ name: "causeCompany" });
    },
  },
  computed: {
    ...mapGetters({
      keyDownNows: "keyDownNow",
    }),
    hot: function () {
      let f = this.hotStand;
      if (0 <= f <= 20) {
        return 1;
      } else if (20 < f <= 40) {
        return 2;
      } else if (40 < f <= 60) {
        return 3;
      } else if (60 < f <= 80) {
        return 4;
      } else {
        return 5;
      }
    },
    hotArray: function () {
      this.array.length = this.hot;
      return this.array;
    },
    lessArray: function () {
      this.lessArrays.length = 5 - this.hot;
      return this.lessArrays;
    },
    cp: function () {
      if (this.isAdd == false) {
        return "收藏";
      } else {
        return "已已收藏";
      }
    },
    contrast: function () {
      return "对比";
    },
  },
};
</script>


<style>
.btn-addCompany {
  width: 140px;
  height: 36px;
  line-height: 36px;
  color: #1270ff !important;
  border: 1px solid #1270ff;
}
</style>
