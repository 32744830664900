<template>
   <div class="row justify-content-center mt-3">
     <span class="default-page  text-center ml-2 br_4" @click="setCurrent(current - 1)"> < </span>
     <span class="default-page  text-center ml-2 br_4" v-for="p in grouplist" :class="{'active': current == p.val}" @click="setCurrent(p.val)"> {{ p.text }} </span>
     <span class="default-page  text-center ml-2 br_4" :class="{'disabled': current == page}" @click="setCurrent(current + 1)">></span>
  </div>
</template>

<script>
    export default {
     data(){
      return {
        current: this.currentPage
      }
    },
    props: {
      total: {// 数据总条数
        type: Number,
        default: 0
      },
      display: {// 每页显示条数
        type: Number,
        default: 10
      },
      currentPage: {// 当前页码
        type: Number,
        default: 1
      },
      pagegroup: {// 分页条数
        type: Number,
        default: 5,
        coerce: function (v) {
          v = v > 0 ? v : 5;
          return v % 2 === 1 ? v : v + 1;
        }
      }
    },
    computed: {
      page: function () { // 总页数
        return Math.ceil(this.total / this.display);
      },
      grouplist: function () { // 获取分页页码
        var len = this.page, temp = [], list = [], count = Math.floor(this.pagegroup / 2), center = this.current;
        if (len <= this.pagegroup) {
          while (len--) {
            temp.push({text: this.page - len, val: this.page - len});
          };
          return temp;
        }
        while (len--) {
          temp.push(this.page - len);
        }
        ;
        var idx = temp.indexOf(center);
        (idx < count) && ( center = center + count - idx);
        (this.current > this.page - count) && ( center = this.page - count);
        temp = temp.splice(center - count - 1, this.pagegroup);
        do {
          var t = temp.shift();
          list.push({
            text: t,
            val: t
          });
        } while (temp.length);
        if (this.page > this.pagegroup) {
          (this.current > count + 1) && list.unshift({text: '...', val: list[0].val - 1});
          (this.current < this.page - count) && list.push({text: '...', val: list[list.length - 1].val + 1});
        }
        return list;
      }
    },
    methods: {
      setCurrent: function (idx) {
        if (this.current != idx && idx > 0 && idx < this.page + 1) {
          this.current = idx;
          this.$emit('pagechange', this.current);
        }
      }
    }


}
</script>


<style >
.default-page{
  width: 24px;
  height: 24px;
  color: #4a81fd;
  border: 1px solid #4a81fd;
  cursor: pointer;
}
.default-page:hover{
  color: #fff;
  background-color: #4a81fd;
}
.default-page.active{
 color: #fff;
  background-color: #4a81fd;
}

</style>
